import React from "react";
import styled from 'styled-components'
import Container from '../components/styled/Container'
import Row from '../components/styled/Row'
import Col from '../components/styled/Col'
import ParallaxPageTitle from '../components/motion/ParallaxPageTitle'
import Callout from '../components/Callout'
import space from '../styles/space'
import { breakpoints } from "../styles/breakpoints";
import getColor from '../utils/getColor'
import { connect } from "react-redux";
import { primaryTitleFont } from "../styles/fonts";
import Portrait from '../components/Portrait'

const StyledDate = styled.h3`
  font-weight: 400;
  font-family: ${primaryTitleFont};
`

export const StyledWorkshop = styled.li`
  :not(:last-child) {
    margin-bottom: ${space.md}px;
    padding-bottom: ${space.md}px;

    border-bottom: 1px solid ${props => getColor(props, 'secondaryColor')};
  }

  width: 100%;

  h2 {
    color: ${props => getColor(props, 'secondaryTitleColor')};
  }
  h3, h4 {
    color: ${props => getColor(props, 'tertiaryTitleColor')};
  }
`

const StyledCallout = styled(Callout)`
  margin-bottom: ${space.xl}px;

  ${breakpoints.md} {
    margin-bottom: ${space.xxl}px;
  }
`

const StyledWorkshopList = styled.ul`
  padding-bottom: ${space.xl}px;

  ${breakpoints.md} {
    padding-bottom: ${space.xxl}px;
  }
`

const WorkshopOrCourse = ({title, location, locationURL, url, date, reduxTheme}) => {
  return (
    <StyledWorkshop className='border-bottom-no-last-child' reduxTheme={reduxTheme}>
      <Row responsive>
        <Col>
          {/* TODO: CHANGE TO USE FORWARD REF FOR CONNECTED PROPS */}
          {title && <h2 className='secondaryTitleColor'>{title}</h2>}
          {location && <h3 className='tertiaryTitleColor'>{location}</h3>}
        </Col>
        <Col align="right">
          {date && <StyledDate className='tertiaryTitleColor'>{date}</StyledDate>}
        </Col>
      </Row>
    </StyledWorkshop>
  ) 
}

const RegularPage = ({reduxTheme}) => (
  <Container>
    <ParallaxPageTitle>
      Devan <br/>
      Huapaya <br/>
    </ParallaxPageTitle>
  
    <Portrait title={false} />
  </Container>
);

export default connect(({theme}) => ({reduxTheme: theme}))(RegularPage);
